import { Service, ServiceType } from '@wix/bookings-uou-types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import {
  QueryAvailabilityRequest,
  QueryAvailabilityResponse,
  Slot,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import {
  convertRfcTimeToLocalDateTimeStartOfDay,
  getTodayLocalDateTimeStartOfDay,
} from '../utils/dateAndTime/dateAndTime';
import {
  createDummyCatalogData,
  isDummyServices,
} from './dummyData/dummyCatalogData';
import { createDummySlots } from './dummyData/dummySlotsData';
import { BookingsApi } from './BookingsApi';
import { CalendarApiInitParams, CalendarCatalogData } from './types';
import {
  CalendarErrors,
  FilterOptions,
  LocalDateTimeRange,
  Optional,
  Preset,
  SlotsAvailability,
} from '../types/types';
import {
  areAllLocationsSelected,
  filterServicesBySelectedLocations,
  filterSlotsBySelectedLocations,
  isOnlyBusinessLocationsSelected,
  isOtherLocationsSelected,
} from '../utils/selectedLocations/selectedLocations';
import { EmptyStateType } from '../components/BookingCalendar/ViewModel/emptyStateViewModel/emptyStateViewModel';
import { CalendarState } from '../components/BookingCalendar/controller';
import {
  ControllerFlowAPI,
  ControllerParams,
  IUser,
} from '@wix/yoshi-flow-editor';
import { AddError } from '../components/BookingCalendar/Actions/addError/addError';
import { createDummyDateAvailability } from './dummyData/dummyDateAvailability';
import { Balance } from '@wix/ambassador-pricing-plan-benefits-server/types';
import { Booking, BookingAdapter } from '@wix/bookings-checkout-api';
import {
  isCalendarPage,
  isCalendarWidget,
  isDailyAgendaWidget,
  isWeeklyTimeTableWidget,
} from '../utils/presets';
import { isLayoutWithTimeSlot } from '../utils/layouts';
import { getAvailableServicesByPreset } from '../utils/state/getAvailableServicesByPreset';

export const CALENDAR_PAGE_URL_PATH_PARAM = 'booking-calendar';

export class CalendarApi {
  private readonly flowAPI: ControllerFlowAPI;
  private wixSdkAdapter: WixOOISDKAdapter;
  private bookingsApi: BookingsApi;
  private readonly reportError: ControllerParams['flowAPI']['reportError'];
  private readonly settingsParams: any;
  private readonly preset: Preset;
  private readonly isBackFromFormWithCart: boolean;

  constructor({
    flowAPI,
    wixSdkAdapter,
    reportError,
    settingsParams,
    preset,
  }: CalendarApiInitParams) {
    this.flowAPI = flowAPI;
    this.settingsParams = settingsParams;
    this.wixSdkAdapter = wixSdkAdapter;
    this.reportError = reportError;
    this.preset = preset;
    this.bookingsApi = new BookingsApi({
      authorization: wixSdkAdapter.getInstance(),
      baseUrl: wixSdkAdapter.getServerBaseUrl(),
      experiments: flowAPI.experiments,
      httpClient: flowAPI.httpClient,
    });
    this.isBackFromFormWithCart = this.wixSdkAdapter.isBackFromFormWithCart();
  }

  async getCatalogData({
    onError,
  }: {
    onError: (type: EmptyStateType) => void;
  }): Promise<Optional<CalendarCatalogData>> {
    const isAppReflowGetServiceSlugFixEnable = this.flowAPI.experiments.enabled(
      'specs.bookings.AppReflowServiceSlug',
    );

    try {
      const isEditorMode = this.wixSdkAdapter.isEditorMode();
      const isCalendarPageInEditorMode =
        isCalendarPage(this.preset) && isEditorMode;
      const isCalendar =
        isCalendarPage(this.preset) || isCalendarWidget(this.preset);

      const serviceSlug = await this.wixSdkAdapter.getServiceSlug(
        CALENDAR_PAGE_URL_PATH_PARAM,
        isAppReflowGetServiceSlugFixEnable,
      );
      const resourceSlug = this.getResourceSlug();
      const selectedLocations = this.flowAPI.settings.get(
        this.settingsParams.selectedLocations,
      );
      const selectedCategories = this.flowAPI.settings.get(
        this.settingsParams.selectedCategories,
      );
      const selectedService = this.flowAPI.settings.get(
        this.settingsParams.selectedService,
      );
      const shouldFilterBySlug =
        !this.isBackFromFormWithCart && isCalendarPage(this.preset);

      const catalogData = await this.bookingsApi.getCatalogData({
        servicesOptions: {
          ...(shouldFilterBySlug ? { slug: serviceSlug } : {}),
          include: !isCalendarPageInEditorMode,
          businessLocations:
            !isCalendarPageInEditorMode &&
            this.shouldFilterBySelectedLocationsOnServerSide()
              ? selectedLocations
              : undefined,
          categories:
            selectedCategories?.length > 0 ? selectedCategories : undefined,
          id: isCalendarWidget(this.preset) ? selectedService : undefined,
          tags: isCalendar
            ? [ServiceType.GROUP, ServiceType.INDIVIDUAL]
            : [ServiceType.GROUP],

          isBookOnlineAllowed: isCalendar ? true : undefined,
        },
        resourcesOptions: {
          slug: resourceSlug,
          include: !isCalendarPageInEditorMode && !!resourceSlug,
        },
      });

      if (isEditorMode) {
        const shouldUseDummyData = () => {
          if (isCalendarPage(this.preset)) {
            return true;
          }
          if (isCalendarWidget(this.preset)) {
            return selectedService === '';
          }
          if (
            isWeeklyTimeTableWidget(this.preset) ||
            isDailyAgendaWidget(this.preset)
          ) {
            return !catalogData.services.length;
          }
          return true;
        };
        const useDummyData = shouldUseDummyData();
        if (useDummyData) {
          return {
            ...createDummyCatalogData(this.flowAPI),
            businessInfo: catalogData.businessInfo,
          };
        }
      }

      if (isCalendarPage(this.preset) || isCalendarWidget(this.preset)) {
        if (
          this.flowAPI.experiments.enabled(
            'specs.bookings.calendarServicesDropdownRefactor',
          )
        ) {
          this.populateRequestedServiceAndAllServices(catalogData, serviceSlug);
        } else {
          this.placeRequestedServiceFirst(catalogData, serviceSlug);
        }
      }

      if (this.shouldFilterBySelectedLocationsOnClientSide()) {
        catalogData.services = filterServicesBySelectedLocations(
          selectedLocations,
          catalogData.services,
        );
      }

      const { services } = catalogData;
      if (!services.length || !services[0]) {
        onError(EmptyStateType.SERVICE_NOT_FOUND);
        return catalogData;
      }

      if (
        isLayoutWithTimeSlot(this.flowAPI.settings, this.settingsParams) &&
        services[0]?.payment.paymentDetails.isVariedPricing
      ) {
        catalogData.serviceVariants = await this.bookingsApi.getServiceVariants(
          services[0].id,
        );
      }

      return catalogData;
    } catch (e) {
      const authorization = this.wixSdkAdapter.getInstance();
      this.reportError(
        `failed to get catalog data from catalog bulk with error: ${e} with authorization ${authorization}` as
          | string
          | Error,
      );
      onError(EmptyStateType.SERVER_ERROR);
    }
  }

  async getNextAvailableDate(
    { fromAsLocalDateTime, toAsLocalDateTime }: LocalDateTimeRange,
    {
      state,
      onError,
    }: {
      state: CalendarState;
      onError: AddError;
    },
  ): Promise<Optional<string>> {
    try {
      const availabilityCalendarRequest: QueryAvailabilityRequest =
        this.buildQueryAvailabilityRequest({
          from: fromAsLocalDateTime,
          to: toAsLocalDateTime,
          state,
          getNextAvailableSlot: true,
        });
      const slotAvailability = await this.bookingsApi.getSlotsAvailability(
        availabilityCalendarRequest,
      );

      const nextAvailableDate =
        slotAvailability?.availabilityEntries?.[0]?.slot?.startDate;
      if (nextAvailableDate) {
        return convertRfcTimeToLocalDateTimeStartOfDay(nextAvailableDate!);
      }
      onError(CalendarErrors.NO_NEXT_AVAILABLE_DATE_WARNING);
    } catch (e) {
      this.reportError(e as string | Error);
      onError(CalendarErrors.NEXT_AVAILABLE_DATE_SERVER_ERROR);
    }
  }

  async getDateAvailability(
    { fromAsLocalDateTime, toAsLocalDateTime }: LocalDateTimeRange,
    {
      state,
    }: {
      state: CalendarState;
    },
  ): Promise<Optional<QueryAvailabilityResponse>> {
    if (this.wixSdkAdapter.isEditorMode()) {
      return createDummyDateAvailability();
    }

    try {
      let from;
      const { selectedTimezone } = state;
      const todayLocalDateTime = getTodayLocalDateTimeStartOfDay(
        selectedTimezone!,
      );
      if (new Date(toAsLocalDateTime) < new Date(todayLocalDateTime)) {
        return {};
      } else {
        from =
          new Date(todayLocalDateTime) > new Date(fromAsLocalDateTime)
            ? todayLocalDateTime
            : fromAsLocalDateTime;
      }
      const availabilityCalendarRequest: QueryAvailabilityRequest =
        this.buildQueryAvailabilityRequest({
          from,
          to: toAsLocalDateTime,
          state,
          shouldLimitPerDay: true,
        });

      return await this.bookingsApi.getSlotsAvailability(
        availabilityCalendarRequest,
      );
    } catch (e) {
      this.reportError(e as string | Error);
    }
  }

  async getSlotsInRange(
    { fromAsLocalDateTime, toAsLocalDateTime }: LocalDateTimeRange,
    {
      state,
      onError,
    }: {
      state: CalendarState;
      onError: AddError;
    },
  ): Promise<Optional<QueryAvailabilityResponse>> {
    const shouldCreateDummySlots =
      this.wixSdkAdapter.isEditorMode() &&
      isDummyServices(state.availableServices);
    if (shouldCreateDummySlots) {
      return createDummySlots({
        flowAPI: this.flowAPI,
        settingsParams: this.settingsParams,
        from: fromAsLocalDateTime,
      });
    }

    try {
      const availabilityCalendarRequest: QueryAvailabilityRequest =
        this.buildQueryAvailabilityRequest({
          from: fromAsLocalDateTime,
          to: toAsLocalDateTime,
          state,
        });

      const slotAvailability = await this.bookingsApi.getSlotsAvailability(
        availabilityCalendarRequest,
      );

      if (isLayoutWithTimeSlot(this.flowAPI.settings, this.settingsParams)) {
        slotAvailability.availabilityEntries =
          this.getOnlyFutureSlotAvailabilities(
            slotAvailability?.availabilityEntries,
          );
      }

      if (this.shouldFilterBySelectedLocationsOnClientSide()) {
        const selectedLocations = this.flowAPI.settings.get(
          this.settingsParams.selectedLocations,
        );
        slotAvailability.availabilityEntries = filterSlotsBySelectedLocations(
          selectedLocations,
          slotAvailability?.availabilityEntries,
        );
      }

      return slotAvailability;
    } catch (e) {
      this.reportError(e as string | Error);
      onError(CalendarErrors.AVAILABLE_SLOTS_SERVER_ERROR);
    }
  }

  async getBookingDetails({
    onError,
  }: {
    onError: (type: EmptyStateType) => void;
  }): Promise<Optional<Booking>> {
    const bookingId = this.wixSdkAdapter.getUrlQueryParamValue(
      BookingsQueryParams.BOOKING_ID,
    );
    if (!bookingId || this.wixSdkAdapter.isSSR()) {
      return;
    }
    try {
      const booking = await this.bookingsApi.getBookingDetails(bookingId);
      if (!booking) {
        onError(EmptyStateType.BOOKING_NOT_FOUND);
        return;
      }
      return booking;
    } catch (e: any) {
      this.reportError(e as string | Error);
      const errorType =
        e?.httpStatus === 403
          ? EmptyStateType.GET_BOOKING_DETAILS_ACCESS_DENIED
          : EmptyStateType.GET_BOOKING_DETAILS_ERROR;
      onError(errorType);
    }
  }

  async rescheduleBooking({
    booking,
    slot,
    onError,
  }: {
    booking: Booking;
    slot: Slot;
    onError: AddError;
  }) {
    try {
      return await this.bookingsApi.rescheduleBooking({ booking, slot });
    } catch (e) {
      this.reportError(e as string | Error);
      onError(CalendarErrors.RESCHEDULE_SERVER_ERROR);
    }
  }

  async getPurchasedPricingPlans({
    currentUser,
    service,
  }: {
    currentUser: IUser;
    service?: Service;
  }): Promise<Balance[]> {
    const isServiceConnectedToPricingPlans =
      !!service?.payment?.pricingPlanInfo?.pricingPlans?.length;

    const shouldGetPurchasedPricingPlans =
      currentUser?.loggedIn && isServiceConnectedToPricingPlans;

    try {
      const contactId = currentUser.id;
      if (
        this.wixSdkAdapter.isEditorMode() ||
        this.wixSdkAdapter.isPreviewMode() ||
        !shouldGetPurchasedPricingPlans
      ) {
        return [];
      }
      return await this.bookingsApi.getPurchasedPricingPlans({
        contactId,
        authorization: this.wixSdkAdapter.getInstance(),
      });
    } catch (e) {
      this.reportError(e as string | Error);
      return [];
    }
  }

  private getOnlyFutureSlotAvailabilities(
    availableSlots?: SlotAvailability[],
  ): SlotAvailability[] {
    const now = new Date();
    const onlyFutureEntries = availableSlots?.filter((availabilityEntry) => {
      const rfcStartTime = availabilityEntry?.slot?.startDate;
      return rfcStartTime && new Date(rfcStartTime) >= now;
    });
    return onlyFutureEntries || [];
  }

  private placeRequestedServiceFirst(
    catalogData: CalendarCatalogData,
    serviceSlug: string,
  ) {
    if (serviceSlug && this.isBackFromFormWithCart) {
      const { services, seoData } = catalogData;
      const requestedServiceIndex = services.findIndex((service) =>
        service.info.slugs.some((slug) => slug === serviceSlug),
      );

      if (requestedServiceIndex === -1) {
        catalogData.services = [];
        catalogData.seoData = [];
        return;
      }

      const [requestedService] = services.splice(requestedServiceIndex, 1);

      catalogData.services = [requestedService, ...services];

      const [requestedSEOService] = seoData!.splice(requestedServiceIndex, 1);
      catalogData.seoData = [requestedSEOService];
    }
  }

  private populateRequestedServiceAndAllServices(
    catalogData: CalendarCatalogData,
    serviceSlug: string,
  ) {
    if (serviceSlug && this.isBackFromFormWithCart) {
      const { services, seoData } = catalogData;
      const requestedServiceIndex = services.findIndex((service) =>
        service.info.slugs.some((slug) => slug === serviceSlug),
      );

      if (requestedServiceIndex === -1) {
        catalogData.services = [];
        catalogData.seoData = [];
        return;
      }

      const [requestedService] = services.splice(requestedServiceIndex, 1);

      catalogData.services = [requestedService];
      catalogData.allCalendarBookableServices = services;

      const [requestedSEOService] = seoData!.splice(requestedServiceIndex, 1);
      catalogData.seoData = [requestedSEOService];
    }
    if (!serviceSlug) {
      catalogData.allCalendarBookableServices = this.isBackFromFormWithCart
        ? catalogData.services.slice(1)
        : undefined;
      catalogData.services = catalogData.services.slice(0, 1);
    }
  }

  private shouldFilterBySelectedLocationsOnClientSide() {
    const selectedLocations = this.flowAPI.settings.get(
      this.settingsParams.selectedLocations,
    );
    return (
      !isLayoutWithTimeSlot(this.flowAPI.settings, this.settingsParams) &&
      !areAllLocationsSelected(selectedLocations) &&
      isOtherLocationsSelected(selectedLocations)
    );
  }

  private shouldFilterBySelectedLocationsOnServerSide() {
    return (
      !isLayoutWithTimeSlot(this.flowAPI.settings, this.settingsParams) &&
      isOnlyBusinessLocationsSelected(
        this.flowAPI.settings.get(this.settingsParams.selectedLocations),
      )
    );
  }

  private getResourceSlug() {
    const staffQueryParam = this.wixSdkAdapter.getUrlQueryParamValue(
      BookingsQueryParams.STAFF,
    );

    if (staffQueryParam) {
      if (Array.isArray(staffQueryParam)) {
        return staffQueryParam[0];
      } else {
        return staffQueryParam;
      }
    }
  }

  private getBusinessLocationsFilterForQueryAvailabilityRequest(
    state: CalendarState,
    settings: any,
  ) {
    const { filterOptions } = state;
    const isLayoutWithTimeSlots = isLayoutWithTimeSlot(
      settings,
      this.settingsParams,
    );
    const selectedLocations = settings.get(
      this.settingsParams.selectedLocations,
    );
    const shouldFilterByBusinessLocations = isLayoutWithTimeSlots
      ? filterOptions.LOCATION.length > 0
      : isOnlyBusinessLocationsSelected(selectedLocations);

    if (shouldFilterByBusinessLocations) {
      return {
        'location.businessLocation.id': isLayoutWithTimeSlots
          ? filterOptions.LOCATION
          : selectedLocations,
      };
    }
    return {};
  }

  private getOpenSpotsFilterForQueryAvailabilityRequest({
    state,
    getNextSlotNotFullAndNotTooLateToBook,
  }: {
    state: CalendarState;
    getNextSlotNotFullAndNotTooLateToBook: boolean;
  }) {
    const { rescheduleBookingDetails, availableServices } = state;

    const services = this.flowAPI.experiments.enabled(
      'specs.bookings.calendarServicesDropdownRefactor',
    )
      ? availableServices
      : getAvailableServicesByPreset({
          availableServices,
          preset: this.preset,
        });
    const isIndividualService = services.some(
      (service) => service.info.type === ServiceType.INDIVIDUAL,
    );

    if (rescheduleBookingDetails) {
      const bookingAdapter = new BookingAdapter(rescheduleBookingDetails);
      const numberOfParticipants = bookingAdapter.numberOfParticipants!;
      return { openSpots: { $gte: `${numberOfParticipants}` } };
    } else if (isIndividualService || getNextSlotNotFullAndNotTooLateToBook) {
      return { openSpots: { $gte: '1' } };
    }
    return {};
  }

  private getMaximumSupportedServicesForQueryAvailabilityRequest(
    availableServiceIds: string[],
  ) {
    return availableServiceIds.slice(0, 100);
  }

  private buildServiceFilter({
    availableServices,
    filterOptions,
  }: {
    filterOptions: FilterOptions;
    availableServices: Service[];
  }) {
    const services = this.flowAPI.experiments.enabled(
      'specs.bookings.calendarServicesDropdownRefactor',
    )
      ? availableServices
      : getAvailableServicesByPreset({
          availableServices,
          preset: this.preset,
        });

    const serviceIds =
      filterOptions.SERVICE.length > 0
        ? filterOptions.SERVICE
        : services.map((service) => `${service?.id}`);

    return this.getMaximumSupportedServicesForQueryAvailabilityRequest(
      serviceIds,
    );
  }

  private buildQueryAvailabilityRequest({
    from,
    to,
    state,
    shouldLimitPerDay = false,
    getNextAvailableSlot = false,
  }: {
    from: string;
    to: string;
    state: CalendarState;
    shouldLimitPerDay?: boolean;
    getNextAvailableSlot?: boolean;
  }): QueryAvailabilityRequest {
    const { selectedTimezone, filterOptions, availableServices } = state;

    const onlyAvailableSlots =
      this.flowAPI.settings.get(this.settingsParams.slotsAvailability) ===
      SlotsAvailability.ONLY_AVAILABLE;

    const getNextSlotNotFullAndNotTooLateToBook =
      getNextAvailableSlot && !onlyAvailableSlots;

    const businessLocationsFilter =
      this.getBusinessLocationsFilterForQueryAvailabilityRequest(
        state,
        this.flowAPI.settings,
      );

    const openSpotsFilter = this.getOpenSpotsFilterForQueryAvailabilityRequest({
      state,
      getNextSlotNotFullAndNotTooLateToBook,
    });

    const serviceIds = this.buildServiceFilter({
      availableServices,
      filterOptions,
    });

    const isAllowAvailabilityProxy = this.flowAPI.experiments.enabled(
      'specs.bookings.calendar.availabilityProxy',
    );

    return {
      ...(isAllowAvailabilityProxy ? { allowProxyToAvailability: true } : {}),
      timezone: selectedTimezone,
      ...(shouldLimitPerDay ? { slotsPerDay: 1 } : {}),
      query: {
        filter: {
          serviceId: serviceIds,
          startDate: from,
          endDate: to,
          ...(onlyAvailableSlots ? { bookable: true } : {}),
          ...(filterOptions.STAFF_MEMBER.length > 0
            ? { resourceId: filterOptions.STAFF_MEMBER }
            : {}),
          ...businessLocationsFilter,
          ...openSpotsFilter,
          ...(getNextSlotNotFullAndNotTooLateToBook
            ? { 'bookingPolicyViolations.tooLateToBook': false }
            : {}),
        },
        ...(getNextAvailableSlot ? { cursorPaging: { limit: 1 } } : {}),
      },
    };
  }

  isEcomSite() {
    return this.bookingsApi.isEcomSite();
  }
}
